module.exports = {
  Home: '主页',
  Latest: '近期',
  About: '关于',
  Brand: '品牌',
  Project: '项目',
  Client: '客户',
  Contact: '联系',
  Latest_Project: '近期项目',
  SoldOut: '零售',
  LogTalk: '物流',
  iTall: '科创',
  PharmaX: '医药',
  Insight: '金融',
  iMFG: '制造',
  LearnMore: '了解更多',
  soldout_text: '存量竞争、经济下行的形势巨变下，零售渠道如何孵化品牌并强势突围，回应愈加复杂多元的消费者需求？Sold Out新引响是SmartRetail智慧零售峰会的升级品牌，沉淀六载，潜心与150+家中国零售商和品牌商捕捉零售场景新变化，以强势姿态置身消费新格局。',
  logtalk_text: '全球进入供应链竞争时代，面对供应链数字化需求，我国传统供应链亟需变革跃迁，助推我国供给侧结构性改革。Logtalk零售物流与供应链峰会携手200+国内外主流零售商、品牌商等企业决策团队与代表，以提质增效为中心，探讨中国零售企业如何应对新一轮洗牌浪潮，摆脱供应链承压困境，实现价值链数字力、商品力、竞争力的提升。',
  itall1_text: '以大数据、云计算、人工智能为代表的第四次工业革命进程迅猛，国家数字经济建设进入加速期，抢占数字经济产业链制高点。然而企业数智化技术发展道阻且长，自主创新与国产化又渐成行业共识，iTall中国科创峰会将联合200+家龙头企业科创英才，围绕企业发展瓶颈，聚焦数字化转型痛点，讨论构建企业内生长效发展力。',
  itall2_text: "",
  pharmax1_text: '近年来，我国医药产业驶入发展快车道，但仍然面临着产品同质化、价格竞争激烈的问题。面对当前巨大的用药需求，该如何寻到符合立足中国、面向全球的特色化发展的创新之路？PharmaX未来医药峰会，为200+家医药行业上下游搭建一个交流趋势及创新的平台，拟邀请医药领域研究、开发及运营与投资方面的实战专家亲临现场，与与会嘉宾共论趋势、技术及经验。',
  pharmax2_text: '',
  insight_text: '新一期“金融科技发展规划”要求，新阶段要以数据要素应用为基础，并围绕基础设施建设，产业生态建设提出多项重点任务。INsight系列峰会与银行、证券、基金、保险等机构英才齐力探寻推动金融基础设施、金融业务模式、组织内部架构的多层次创新实践，全面释放数据要素值，为客户打造出更好的服务体验。',
  imfg_text: '新一代信息技术覆盖制造企业设计、生产、管理等各环节，可以实现效率与价值的双提升。imfg中国智造峰会与百家制造厂商携手梳理生产参与各要素、各环节，围绕制造业短板精准发力，促新技术和制造业融合创新。',
  CHO_text: "数字化正在赋能各产业链的优化升级，制造、零售、金融、医药等诸多行业书写新的发展篇章。新生态、新模式蓬勃发展的同时，数字人才需求不断攀升。CHO数字人才系列峰会汇聚多行业人资菁英，旨在增加高层数字人才存量、提升数字人才质量，适应人才需求结构变化。",
  about_us: '关于我们',
  about_us1_text: '作为一家富有创意的商业活动管理公司，会誉会展专注为中国及亚太市场中的优秀企业、政府机构、非政府和非营利组织提供活动管理服务，以创意连接为服务宗旨，将实现商业全球化互通作为必修课，帮助来自各行各业的客户刷新行业认知、解决行业痛点、精准对接商机，成为立足华东、辐射全国、面向世界的行业活动实干者。',
  about_us2_text: '会誉会展的核心团队均来自于国内外知名会展以及咨询企业。历经多年大型活动的经验沉淀，敏锐的洞察力、持续的创造力、高效的执行力、出色的协调力、专注且易于合作的职业精神、系统且精密的专业知识使得会誉会展团队从战略和规划到交付与审查，都是值得信赖的专业团队。',
  Core_business: '核心业务',
  Core_business_text: '我们相信，以客户视角审视概念到执行的个中流程，再加以多种创意，是团队为客户提供优质而高效服务的不二法宝。我们通过体系化的方式，实现连接驱动业务，彰显商业新价值，目前我们的主要业务涵盖以下：',
  business1: '峰会论坛',
  business2: '沙龙培训',
  business3: '考察研学',
  business4: '活动承接',
  business1_text: '通过国际会议、高峰论坛、专业展览，帮助企业客户完成高效、精准业务拓展，让企业实现商业价值新增涨。',
  business2_text: '商业活动专业管理服务能力，建立面向全行业、全产业链、全流程化的商务社交平台，助力企业客户调研、策划、运营、执行与维护高规格活动。',
  business3_text: '挖掘行业专家顾问团以研讨会、培训会的方式提升企业客户员工的工作表现与行业理解。',
  business4_text: '定制调研与营销解决方案，以行业强大资源，精细化收集核心反馈与覆盖式营销推广。',
  Brand_Events: "品牌活动",
  Project_Review: '项目回顾',
  Project_Review_text: "会誉会展洞察商业变革、企业创新，挖掘多种行业对技术、业务增长的需求，策划并成功召开多种主题的系列品牌活动，强化行业极速联动，成为多领域合作的重要牵引。会誉紧扣市场新风向，旨在发挥商业连接重要平台作用。聚焦行业政策步伐、汇聚企业英才共识、推进市场多方合作、实现探索创新有效落实，将是我们一直坚持的实质基准。",
  proj1: 'CHO数字人才驱动力峰会',
  proj1_place: '上海',
  proj2: 'INSIGHT保险洞察力峰会',
  proj2_place: '北京',
  proj3: 'LOGTALK零售物流与供应链',
  proj3_place: '上海',
  proj4: 'CHO数字人才峰会',
  proj4_place: '新加坡',
  proj5: 'INSight金融洞察力峰会',
  proj5_place: '北京',
  proj6: 'CHO医药新质人才峰会',
  proj6_place: '上海',
  more_proj: '更多精彩项目回顾',
  our_clients: '我们的客户',
  latest_projects: '近期项目',
  latest1: 'Soldout，零售，7.20-21，上海',
  latest2: '2025.2.20，CHO峰会',
  latest3: '2025.6.19，iMFG智能制造峰会',
  latest4: 'PharmaX，医药，3.16，上海',
  latest5: '2025.3.27，LogTalk物流与供应链峰会',
  latest6: 'iTall，科创年度峰会，11.23，上海',
  Company_Introduction: '公司介绍',
  events1: '零售',
  events2: '网络安全',
  events3: '信息技术',
  events4: '金融科技',
  events5: '人力资源',
  events6: '新能源',
  events7: '物流与供应链',
  events8: '教育',
  events9: '医药',
  events10: '营销',
  events11: '地产',
  events12: '知识产权',
  events13: '快消',
  events14: '新能源汽车',
  events15: '制造',
  contact_us: '联系我们',
  addr1: '上海市普陀区中江路388',
  addr2: '国盛中心1号楼6楼',
  "敬请期待": "敬请期待",
  CHO: '人力',
  "请认真填写此表，提交后会有专人与您联系":"请认真填写此表，提交后会有专人与您联系",
  "活动日历":"活动日历",
  "LOGTALK零售物流与供应链峰会":"LOGTALK零售物流与供应链峰会",
  "INsight保险洞察力峰会":"INsight保险洞察力峰会",
  "CHO数字人才驱动力峰会":"CHO数字人才驱动力峰会",
  "金融新质人才力峰会":"金融新质人才力峰会",
  "PharmaX未来医药峰会":"PharmaX未来医药峰会",
  "2024.3.15|上海":"2024.3.15|上海",
  "2024.3.21|北京":"2024.3.21|北京",
  "2024.4.25|上海":"2024.4.25|上海",
  "2024.6.26|北京":"2024.6.26|北京",
  "2024.6.27|上海":"2024.6.27|上海",
  "零售":"零售",
  "营销":"营销",
  "快消":"快消",
  "地产":"地产",
  "物流与供应链":"物流与供应链",
  "金融与保险科技":"金融与保险科技",
  "数字货币":"数字货币",
  "人力资源":'人力资源',
  "科创":"科创",
  "网络安全":"网络安全",
  "制造":'制造',
  "医药":'医药',
  "知识产权":"知识产权",
  "教育":"教育",
  "新能源":"新能源",
  "项目":"项目",
  "请选择想咨询的项目":"请选择想咨询的项目",
  "其他":"其他",
  "零售":'零售',
  "物流":"物流",
  "科创":"科创",
  "医药":'医药',
  "金融":"金融",
  "制造":"制造",
  "姓名":"姓名",
  "请输入您的姓名":"请输入您的姓名",
  "公司":'公司',
  "请输入您的公司":"请输入您的公司",
  "职位":"职位",
  "请输入您的职位":"请输入您的职位",
  "联系电话":"联系电话",
  "请输入您的联系电话":"请输入您的联系电话",
  "公司邮箱":"公司邮箱",
  "请输入您的公司邮箱":"请输入您的公司邮箱",
  "描述":"描述",
  "请简单描述您的需求(选填)":"请简单描述您的需求(选填)",
  "请输入验证码":"请输入验证码",
  "换一张":"换一张",
  "提交":"提交"
}